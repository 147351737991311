import { UserRole } from '../graphql';

export type Color = keyof typeof colors;

export const colors = {
  primary: '#112A34',
  primary1: '#112A34',
  primary3: '#112A34',
  primary5: '#112A34BB',
  primary6: '#112A34',

  black1: '#F2F2F5',
  black2: '#CECED9',
  black3: '#B6B6BF',
  black4: '#9D9DA6',
  black5: '#85858C',
  black6: '#5C5C5C',
  black7: '#B0B0BA',
  black8: '#3D3D40',
  black10: '#0D0D0D',

  blue1: '#F3F5F6',
  blue2: '#D8EDF1',
  blue3: '#B1DBE3',
  blue4: '#8ACAD5',
  blue5: '#112A34',
  blue6: '#112A34',
  blue7: '#3490A1',
  blue8: '#2C7987',
  blue9: '#24626E',
  blue10: '#1B4C54',

  Cyan7: '#08979C',

  red5: '#FF4D4F',
  red6: '#F5222D',

  orange6: '#FA8C16',

  colorWhite: '#FFFFFF',

  defaultColor: '#0D0D0D',
  defaultBorderColor: '#D9D9D9',
  defaultBg: '#FFFFFF',

  background: '#F3F5F6',
  darkBackground: '#0C0D0E',
  colorItemText: '#B3B3B3',
  colorTextLightSolid: '#FFFFFF',
  colorTextPlaceholder: '#5C5C5C',
  colorIcon: '#6E6E6E',
  borderColor: '#D9D9D9',
  colorTextDescription: '#85858C',

  //App Colors
  black: '#000',
  white: '#FFF',

  primary50: '#FCFEFF',
  primary100: '#F5FAFF',
  primary300: '#A3D3FF',
  primary500: '#0086FF',
  primary600: '#006BCC',
  primary700: '#005099',
  primary800: '#003666',
  primary900: '#001B33',

  success: '#50B17F',
  successAccent: '#E9FDF2',
  error: '#EA332D',
  errorAccent: '#FDF6E9',
  warning: '#F2AB3C',
  warningAccent: '#FBE9E9',
  progress: '#D87A16',

  //secondary
  secondary: '#050505',
  secondary50: '#F1F2FC',
  secondary100: '#DDDEE8',
  secondary200: '#CCCDD6',
  secondary300: '#B6B7BF',
  secondary400: '#9B9CA3',
  secondary500: '#7C7C82',
  secondary600: '#37373C',
  secondary700: '#252527',
  secondary800: '#181819',
  secondary900: '#050505',
};

export const breakpoints = {
  screenXS: 395,
  screenSM: 600,
  screenMD: 960,
  screenLG: 1280,
  screenXL: 1440,
  screenXXL: 1920,
};

export const storytellerQuestions = {
  background:
    'Share the origin of your storytelling journey. What pivotal moment sparked your passion for sharing ocean-related stories?',
  memorableInfluences:
    'Reflect on specific memories from your upbringing that profoundly influenced your connection to the ocean. How have these experiences shaped your storytelling?',
  professionalPathways:
    'What professional paths have you pursued since leaving school, and how have they contributed to your growth as a storyteller?',
  currentInspirations:
    'Who or what serves as your current source of inspiration in the world of ocean storytelling?',
  ongoingProjects:
    'Briefly describe the projects or stories you’re currently working on. What themes or messages are you aiming to convey?',
  impactfulChanges:
    'If given the chance, what positive change would you most like to bring about in the world or the environment through your storytelling?',
  influentialMedia:
    'Are there any books, documentaries, or other media that have significantly influenced your thinking as an ocean storyteller? Feel free to recommend them to fellow ocean lovers.',
  legacyAspirations:
    'What legacy do you hope your storytelling leaves behind? How would you like to be remembered?',
  guidingAdvice:
    'For aspiring ocean storytellers, what advice would you offer to help them pursue their dreams and make a meaningful impact?',
};

export const guardianQuestions = {
  missionPassion:
    'Briefly outline your organisation’s mission and commitment to ocean conservation. What drives your passion for safeguarding the oceans?',
  guidingValues:
    'Share the foundational values that guide your organisation’s work in protecting and preserving the oceans.',
  impactfulContributions:
    'Describe the positive impact your organisation aims to achieve in the realm of conservation and ocean protection.',
  conservationInitiatives:
    'Highlight the primary conservation initiatives your organisation is currently involved in. How do these initiatives contribute to ocean sustainability?',
  inspirationalInfluences:
    'Are there specific individuals, organisations, or brands within the ocean conservation community that inspire your work? How have they influenced your approach to advocacy?',
  futureLegacy:
    'What lasting legacy does your organisation aspire to leave in the realm of ocean conservation, and how do you plan to achieve it?',
  advocacyForChange:
    'If your organisation could advocate for one critical change in the world or environment today, what would it be, and how do you envision achieving it?',
  mediaPerspectives:
    'Share any influential books, documentaries, or media that have shaped your organisation’s perspective on ocean conservation.',
  storytellingRole:
    'How does storytelling contribute to your advocacy for ocean conservation? In what ways has storytelling proven to be impactful for your cause?',
  storytellingFundraising:
    'How important is fundraising through storytelling for your advocacy efforts? Are there specific projects or initiatives for which you would like storytellers to contribute to fundraising?',
  guidanceForOthers:
    'For other charities, organisations, or brands looking to join the cause of ocean conservation, what advice or guidance would you offer based on your own experiences?',
};

export const advocateQuestions = {
  localBeach: 'Specify your local beach.',
  inspirations:
    'What/Who currently inspires you? (Share any influential books, documentaries, or media that have shaped your perspective on ocean conservation).',
  coreValues: 'What do you value most in life?',
  environmentalChange:
    'What would you most like to change in the world/environment today?',
  contributions:
    'What would you say you do to help restore health to our oceans?',
  criticalAdvocacyChange:
    'If you could advocate for one critical change in the world or environment today, what would it be, and how do you envision achieving it?',
};

export const ambassadorQuestions = {
  oceanDiscovery: 'How did you become a Junior Ocean Ambassador?',
  educationalPursuits:
    'As a young advocate, how do you engage in educational activities related to the oceans? What topics fascinate you the most?',
  inspirationalFigures:
    'Who in the ocean conservation community inspires you? How do they influence your commitment to protecting the oceans?',
  youthInitiatives:
    'Describe any initiatives or projects you’ve undertaken to promote ocean awareness among your peers.',
  environmentalChange:
    'If you could make one positive change for the environment today, what would it be, and how would you go about achieving it?',
  favOceanReads:
    'Any favourite books, documentaries, or educational materials about the oceans that you’d recommend to fellow young ocean enthusiasts?',
  futureLegacy:
    'What positive impact do you hope to make as a Junior Ocean Ambassador, and what legacy do you aspire to leave for future generations?',
  guidance:
    'If you could give advice to other young individuals interested in ocean advocacy, what would it be?',
};

export const getRoleText = (role: UserRole) => {
  switch (role) {
    case 'STORYTELLER':
      return 'Storyteller of the Sea';
    case 'GUARDIAN':
      return 'Ocean Culture Life Guardian';
    case 'AMBASSADOR':
      return 'Junior Ocean Ambassador';
    case 'ADVOCATE':
      return 'Ocean Advocate';
    default:
      return '';
  }
};

type FieldData = {
  title: string;
  subtitle: string;
};

export const storytellerQuestionFields: (FieldData & {
  field?: keyof typeof storytellerQuestions;
})[] = [
  {
    title: 'Introduction to your Journey',
    subtitle: storytellerQuestions.background,
    field: 'background',
  },
  {
    title: 'Memorable Influences',
    subtitle: storytellerQuestions.memorableInfluences,
    field: 'memorableInfluences',
  },
  {
    title: 'Professional Pathways',
    subtitle: storytellerQuestions.professionalPathways,
    field: 'professionalPathways',
  },
  {
    title: 'Current Inspirations',
    subtitle: storytellerQuestions.currentInspirations,
    field: 'currentInspirations',
  },
  {
    title: 'Ongoing Projects',
    subtitle: storytellerQuestions.ongoingProjects,
    field: 'ongoingProjects',
  },
  {
    title: 'Impactful Changes',
    subtitle: storytellerQuestions.impactfulChanges,
    field: 'impactfulChanges',
  },
  {
    title: 'Influential Media',
    subtitle: storytellerQuestions.influentialMedia,
    field: 'influentialMedia',
  },
  {
    title: 'Legacy Aspirations',
    subtitle: storytellerQuestions.legacyAspirations,
    field: 'legacyAspirations',
  },
  {
    title: 'Guiding Advice',
    subtitle: storytellerQuestions.guidingAdvice,
    field: 'guidingAdvice',
  },
  {
    title: 'Add images and videos to your profile',
    subtitle:
      'To stylize your personal page, you can upload images and videos here.',
  },
];

export const advocateQuestionFields: (FieldData & {
  field?: keyof typeof advocateQuestions;
})[] = [
  {
    title: 'Inspirations',
    subtitle: advocateQuestions.inspirations,
    field: 'inspirations',
  },
  {
    title: 'Core Values',
    subtitle: advocateQuestions.coreValues,
    field: 'coreValues',
  },
  {
    title: 'Environmental Change',
    subtitle: advocateQuestions.environmentalChange,
    field: 'environmentalChange',
  },
  {
    title: 'Contributions to Ocean Health',
    subtitle: advocateQuestions.contributions,
    field: 'contributions',
  },
  {
    title: 'Critical Advocacy Change',
    subtitle: advocateQuestions.criticalAdvocacyChange,
    field: 'criticalAdvocacyChange',
  },
];

export const guardianQuestionFields: (FieldData & {
  field?: keyof typeof guardianQuestions;
})[] = [
  {
    title: 'Mission and Passion',
    subtitle: guardianQuestions.missionPassion,
    field: 'missionPassion',
  },
  {
    title: 'Guiding Values',
    subtitle: guardianQuestions.guidingValues,
    field: 'guidingValues',
  },
  {
    title: 'Impactful Contributions',
    subtitle: guardianQuestions.impactfulContributions,
    field: 'impactfulContributions',
  },
  {
    title: 'Conservation Initiatives',
    subtitle: guardianQuestions.conservationInitiatives,
    field: 'conservationInitiatives',
  },
  {
    title: 'Inspirational Influences',
    subtitle: guardianQuestions.inspirationalInfluences,
    field: 'inspirationalInfluences',
  },
  {
    title: 'Future Legacy',
    subtitle: guardianQuestions.futureLegacy,
    field: 'futureLegacy',
  },
  {
    title: 'Advocacy for Change',
    subtitle: guardianQuestions.advocacyForChange,
    field: 'advocacyForChange',
  },
  {
    title: 'Media and Perspectives',
    subtitle: guardianQuestions.mediaPerspectives,
    field: 'mediaPerspectives',
  },
  {
    title: 'Role of Storytelling',
    subtitle: guardianQuestions.storytellingRole,
    field: 'storytellingRole',
  },
  {
    title: 'Fundraising Through Storytelling',
    subtitle: guardianQuestions.storytellingFundraising,
    field: 'storytellingFundraising',
  },
  {
    title: 'Guidance for Others',
    subtitle: guardianQuestions.guidanceForOthers,
    field: 'guidanceForOthers',
  },
];

export const ambassadorQuestionFields: (FieldData & {
  field: keyof typeof ambassadorQuestions;
})[] = [
  {
    title: 'Ocean Discovery',
    subtitle: ambassadorQuestions.oceanDiscovery,
    field: 'oceanDiscovery',
  },
  {
    title: 'Educational Pursuits',
    subtitle: ambassadorQuestions.educationalPursuits,
    field: 'educationalPursuits',
  },
  {
    title: 'Inspirational Figures',
    subtitle: ambassadorQuestions.inspirationalFigures,
    field: 'inspirationalFigures',
  },
  {
    title: 'Youth Initiatives',
    subtitle: ambassadorQuestions.youthInitiatives,
    field: 'youthInitiatives',
  },
  {
    title: 'Environmental Change',
    subtitle: ambassadorQuestions.environmentalChange,
    field: 'environmentalChange',
  },
  {
    title: 'Favorite Ocean Reads',
    subtitle: ambassadorQuestions.favOceanReads,
    field: 'favOceanReads',
  },
  {
    title: 'Future Legacy',
    subtitle: ambassadorQuestions.futureLegacy,
    field: 'futureLegacy',
  },
  {
    title: 'Guidance for Peers',
    subtitle: ambassadorQuestions.guidance,
    field: 'guidance',
  },
];

export const templates = [
  { label: 'Sleek', value: 'A' },
  { label: 'Innovative', value: 'B' },
  { label: 'Bold', value: 'C' },
];
