import { Card, Col, Row, Space, Tabs } from 'antd';
import { ProfileUser } from '../../../graphql';
import { useReactiveVar } from '@apollo/client';
import { Text } from '../../../components';
import {
  colors,
  getRoleText,
  getUserLocation,
  openUrlInNewTab,
} from '../../../utils';
import {
  About,
  EditProfileButton,
  Interview,
  Photos,
  Posts,
  SendMessageButton,
  ShareProfileButton,
  TabItem,
  urls,
  Videos,
} from '.';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { startCase } from 'lodash';
import { useMatch } from 'react-router-dom';

export default function TemplateB() {
  const { sm, md, lg } = useBreakpoint();
  const user = useReactiveVar(ProfileUser);
  const isProfile = useMatch('/profile');

  const tabs: TabItem[] = [
    { label: 'photos', content: <Photos />, show: true },
    { label: 'videos', content: <Videos />, show: true },
    { label: 'posts', content: <Posts />, show: true },
    { label: 'interview', content: <Interview />, show: true },
    { label: 'about', content: <About />, show: true },
  ];

  return !!user?.profile ? (
    <Col span={24}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Card className={'card-no-padding'}>
            <Row>
              <Col
                span={24}
                style={{
                  height: sm ? 250 : 150,
                  borderTopLeftRadius: 12,
                  borderTopRightRadius: 12,
                  background: `url(${user.profile.banner}) no-repeat`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
              <Col
                span={24}
                style={{ padding: md ? '0 24px' : '0 16px', maxHeight: 56 }}
              >
                <Space
                  style={{
                    position: 'relative',
                    top: md ? -40 : -53,
                    height: md ? 80 : 106,
                    width: md ? 80 : 106,
                    aspectRatio: 1,
                    padding: md ? 3 : 4,
                    borderRadius: 110,
                    backgroundColor: 'white',
                  }}
                >
                  <img
                    src={user.profile.picture as string}
                    style={{
                      height: md ? 74 : 98,
                      width: md ? 74 : 98,
                      borderRadius: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Space>
              </Col>
              <Col span={24} style={{ paddingBottom: md ? 24 : 16 }}>
                <Row
                  gutter={[24, 24]}
                  style={{ padding: md ? '0 24px' : '0 16px' }}
                >
                  <Col xs={24} lg={6}>
                    <Row gutter={[0, 20]}>
                      <Col span={24} style={{ display: 'grid' }}>
                        <Text variant={'heading4'}>
                          {user.firstName + ' ' + user.lastName}
                        </Text>
                        <Text
                          variant={'smMedium'}
                          color={'black4'}
                          style={{ marginTop: 5 }}
                        >
                          {getRoleText(user.role)}
                        </Text>
                        <Row style={{ marginTop: 14 }}>
                          <Space
                            style={{
                              width: 'fit-content',
                              backgroundColor: colors.black1,
                              borderRadius: 4,
                              padding: '0 10px',
                            }}
                          >
                            <Text>
                              {getUserLocation(user.profile.location)}
                            </Text>
                          </Space>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[14, 14]}>
                          {urls?.map((url) =>
                            !!user.profile?.[
                              url.key as keyof typeof user.profile
                            ] ? (
                              <Col key={url.key}>
                                <Card
                                  onClick={() =>
                                    openUrlInNewTab(
                                      user.profile?.[
                                        url.key as keyof typeof user.profile
                                      ],
                                    )
                                  }
                                  className={'card-no-padding clickable'}
                                  bordered={false}
                                  style={{ boxShadow: 'none' }}
                                >
                                  <img
                                    src={url.icon}
                                    alt={''}
                                    style={{ width: 20, height: 20 }}
                                  />
                                </Card>
                              </Col>
                            ) : (
                              <></>
                            ),
                          )}
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Text variant={'smNormal'} color={'black8'}>
                          {user.profile.bio}
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[20, 16]}>
                          {isProfile && (
                            <Col
                              style={
                                lg
                                  ? { width: '100%' }
                                  : sm
                                  ? {}
                                  : { width: '50%' }
                              }
                            >
                              {isProfile ? (
                                <EditProfileButton
                                  style={lg || !sm ? { width: '100%' } : {}}
                                />
                              ) : (
                                <SendMessageButton />
                              )}
                            </Col>
                          )}
                          <Col
                            style={
                              lg
                                ? { width: '100%' }
                                : sm
                                ? {}
                                : { width: isProfile ? '50%' : '100%' }
                            }
                          >
                            <ShareProfileButton
                              style={lg || !sm ? { width: '100%' } : {}}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} lg={18}>
                    <Row>
                      <Col span={24}>
                        <Tabs
                          items={tabs.map(({ label, content }) => ({
                            key: label,
                            label: startCase(label),
                            children: content,
                          }))}
                          centered
                          style={{ gap: 16 }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Col>
  ) : (
    <></>
  );
}
