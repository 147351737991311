import { gql } from '@apollo/client';

export const GET_USER = gql`
  query getUser {
    getUser {
      id
      firstName
      lastName
      email
      role
      status
      createdAt
      updatedAt
      profile {
        id
        bio
        pronouns
        dob
        location
        picture
        banner
        websiteUrl
        companyName
        jobTitle
        facebookUrl
        instagramUrl
        xUrl
        behanceUrl
        linkedInUrl
        tiktokUrl
        youtubeUrl
        storyteller {
          id
          background
          memorableInfluences
          professionalPathways
          currentInspirations
          ongoingProjects
          impactfulChanges
          influentialMedia
          legacyAspirations
          guidingAdvice
          images
          videoUrls
          template
        }
        guardian {
          id
          missionPassion
          guidingValues
          impactfulContributions
          conservationInitiatives
          inspirationalInfluences
          futureLegacy
          advocacyForChange
          mediaPerspectives
          storytellingRole
          storytellingFundraising
          guidanceForOthers
        }
        advocate {
          id
          inspirations
          coreValues
          environmentalChange
          contributions
          criticalAdvocacyChange
        }
        ambassador {
          id
          oceanDiscovery
          educationalPursuits
          inspirationalFigures
          youthInitiatives
          environmentalChange
          favOceanReads
          futureLegacy
          guidance
        }
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($userId: String!) {
    getUserById(userId: $userId) {
      id
      firstName
      lastName
      email
      role
      status
      createdAt
      updatedAt
      profile {
        id
        bio
        pronouns
        dob
        location
        picture
        banner
        websiteUrl
        companyName
        jobTitle
        facebookUrl
        instagramUrl
        xUrl
        behanceUrl
        linkedInUrl
        tiktokUrl
        youtubeUrl
        storyteller {
          id
          background
          memorableInfluences
          professionalPathways
          currentInspirations
          ongoingProjects
          impactfulChanges
          influentialMedia
          legacyAspirations
          guidingAdvice
          images
          videoUrls
          template
        }
        guardian {
          id
          missionPassion
          guidingValues
          impactfulContributions
          conservationInitiatives
          inspirationalInfluences
          futureLegacy
          advocacyForChange
          mediaPerspectives
          storytellingRole
          storytellingFundraising
          guidanceForOthers
        }
        advocate {
          id
          inspirations
          coreValues
          environmentalChange
          contributions
          criticalAdvocacyChange
          activities
          localBeach
        }
        ambassador {
          id
          school
          oceanDiscovery
          educationalPursuits
          inspirationalFigures
          youthInitiatives
          environmentalChange
          favOceanReads
          futureLegacy
          guidance
        }
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query getAllUsers(
    $exclude: String
    $page: Float
    $take: Float
    $search: String
    $roles: [UserRole!]
    $status: [UserStatus!]
  ) {
    getUsers(
      exclude: $exclude
      page: $page
      take: $take
      search: $search
      roles: $roles
      status: $status
    ) {
      data {
        id
        firstName
        lastName
        email
        role
        status
        rejectionReason
        createdAt
        updatedAt
        distance
        profile {
          lat
          long
          location
          picture
        }
      }
      count
    }
  }
`;

export const GET_USERS_BY_DISTANCE = gql`
  query getUsersByDistance($page: Float!, $take: Float!) {
    getUsersByDistance(page: $page, take: $take) {
      data {
        id
        firstName
        lastName
        email
        role
        status
        rejectionReason
        createdAt
        updatedAt
        distance
        profile {
          location
          picture
        }
      }
      count
    }
  }
`;

export const GET_STORIES = gql`
  query getStories($status: String, $page: Float, $take: Float) {
    getStories(status: $status, page: $page, take: $take) {
      data {
        id
        content
        image
        video
        createdAt
        updatedAt
        likes {
          id
          user {
            id
            firstName
            lastName
            profile {
              pronouns
              picture
            }
          }
        }
        comments {
          id
          comment
          createdAt
          user {
            id
            firstName
            lastName
            profile {
              pronouns
              picture
            }
          }
        }
        user {
          id
          firstName
          lastName
          email
          role
          status
          createdAt
          updatedAt
          profile {
            pronouns
            picture
          }
        }
      }
      count
    }
  }
`;

export const GET_STORIES_BY_GROUP = gql`
  query getStoriesByGroup($groupId: String!, $page: Float, $take: Float) {
    getStoriesByGroup(groupId: $groupId, page: $page, take: $take) {
      data {
        id
        content
        image
        video
        createdAt
        updatedAt
        likes {
          id
          user {
            id
            firstName
            lastName
            profile {
              pronouns
              picture
            }
          }
        }
        comments {
          id
          comment
          createdAt
          user {
            id
            firstName
            lastName
            profile {
              pronouns
              picture
            }
          }
        }
        user {
          id
          firstName
          lastName
          email
          role
          status
          createdAt
          updatedAt
          profile {
            pronouns
            picture
          }
        }
      }
      count
    }
  }
`;

export const GET_STORIES_BY_EVENT = gql`
  query getStoriesByEvent($eventId: String!, $page: Float, $take: Float) {
    getStoriesByEvent(eventId: $eventId, page: $page, take: $take) {
      data {
        id
        content
        image
        video
        createdAt
        updatedAt
        likes {
          id
          user {
            id
            firstName
            lastName
            profile {
              pronouns
              picture
            }
          }
        }
        comments {
          id
          comment
          createdAt
          user {
            id
            firstName
            lastName
            profile {
              pronouns
              picture
            }
          }
        }
        user {
          id
          firstName
          lastName
          email
          role
          status
          createdAt
          updatedAt
          profile {
            pronouns
            picture
          }
        }
      }
      count
    }
  }
`;

export const GET_STORIES_BY_USER = gql`
  query getStoriesByUser($userId: String!, $page: Float, $take: Float) {
    getStoriesByUser(userId: $userId, page: $page, take: $take) {
      data {
        id
        content
        image
        video
        createdAt
        updatedAt
        likes {
          id
          user {
            id
            firstName
            lastName
            profile {
              pronouns
              picture
            }
          }
        }
        comments {
          id
          comment
          createdAt
          user {
            id
            firstName
            lastName
            profile {
              pronouns
              picture
            }
          }
        }
        user {
          id
          firstName
          lastName
          email
          role
          status
          createdAt
          updatedAt
          profile {
            pronouns
            picture
          }
        }
      }
      count
    }
  }
`;

export const GET_STORY_BY_ID = gql`
  query getStoryById($storyId: String!) {
    getStoryById(storyId: $storyId) {
      id
      content
      image
      video
      createdAt
      updatedAt
      likes {
        id
        user {
          id
          firstName
          lastName
          profile {
            pronouns
            picture
          }
        }
      }
      comments {
        id
        comment
        createdAt
        user {
          id
          firstName
          lastName
          profile {
            pronouns
            picture
          }
        }
      }
      user {
        id
        firstName
        lastName
        email
        role
        status
        createdAt
        updatedAt
        profile {
          pronouns
          picture
        }
      }
    }
  }
`;

export const GET_STORY_LIKES = gql`
  query getStoryLikes($storyId: String!) {
    getStoryLikes(storyId: $storyId) {
      id
      createdAt
      user {
        id
        firstName
        lastName
        profile {
          pronouns
          picture
        }
      }
    }
  }
`;

export const GET_COUNTRIES = gql`
  query getCountries {
    getCountries {
      id
      name
    }
  }
`;

export const GET_CITIES_BY_COUNTRY = gql`
  query getCitiesByCountry($country: String!) {
    getCitiesByCountry(country: $country) {
      id
      name
    }
  }
`;

export const GET_GROUPS = gql`
  query getGroups(
    $userId: String
    $page: Float
    $take: Float
    $exclude: String
    $status: String
  ) {
    getGroups(
      userId: $userId
      page: $page
      take: $take
      exclude: $exclude
      status: $status
    ) {
      data {
        id
        name
        description
        banner
        status
        createdAt
        updatedAt
        membersCount
        isMember
        owner {
          id
        }
      }
      count
    }
  }
`;

export const GET_USERS_GROUPS = gql`
  query getUserGroups($page: Float, $take: Float) {
    getUserGroups(page: $page, take: $take) {
      data {
        id
        name
        description
        banner
        status
        createdAt
        updatedAt
        membersCount
        isMember
        owner {
          id
        }
      }
      count
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query getGroupById($groupId: String!, $userId: String) {
    getGroupById(groupId: $groupId, userId: $userId) {
      id
      name
      description
      banner
      status
      createdAt
      updatedAt
      owner {
        id
        firstName
        lastName
        profile {
          picture
        }
      }
      members {
        id
        user {
          id
          firstName
          lastName
          role
          email
          profile {
            picture
            location
          }
        }
      }
      membersCount
      isMember
      media {
        type
        url
      }
    }
  }
`;

export const GET_GROUP_MEMBERS = gql`
  query getGroupMembers($groupId: String!, $page: Float, $take: Float) {
    getGroupMembers(groupId: $groupId, page: $page, take: $take) {
      data {
        id
        user {
          id
          firstName
          lastName
          role
          email
          profile {
            picture
            location
          }
        }
      }
      count
    }
  }
`;

export const GET_EVENTS = gql`
  query getEvents(
    $userId: String
    $page: Float
    $take: Float
    $type: String
    $status: String
  ) {
    getEvents(
      userId: $userId
      page: $page
      take: $take
      type: $type
      status: $status
    ) {
      data {
        id
        title
        description
        banner
        start
        end
        location {
          placeId
          title
          description
          lat
          long
          locationString
        }
        status
        createdAt
        updatedAt
        membersCount
        isMember
      }
      count
    }
  }
`;

export const GET_USERS_EVENTS = gql`
  query getUserEvents($page: Float, $take: Float) {
    getUserEvents(page: $page, take: $take) {
      data {
        id
        title
        description
        banner
        start
        end
        location {
          placeId
          title
          description
          lat
          long
          locationString
        }
        status
        createdAt
        updatedAt
        membersCount
        isMember
      }
      count
    }
  }
`;

export const GET_EVENT_BY_ID = gql`
  query getEventById($eventId: String!, $userId: String) {
    getEventById(eventId: $eventId, userId: $userId) {
      id
      title
      description
      banner
      status
      start
      end
      location {
        placeId
        title
        description
        lat
        long
        locationString
      }
      createdAt
      updatedAt
      owner {
        id
        firstName
        lastName
        profile {
          picture
        }
      }
      members {
        id
        user {
          id
          firstName
          lastName
          role
          email
          profile {
            picture
            location
          }
        }
      }
      membersCount
      isMember
    }
  }
`;

export const GET_EVENT_MEMBERS = gql`
  query getEventMembers($eventId: String!, $page: Float, $take: Float) {
    getEventMembers(eventId: $eventId, page: $page, take: $take) {
      data {
        id
        user {
          id
          firstName
          lastName
          role
          email
          profile {
            picture
            location
          }
        }
      }
      count
    }
  }
`;

export const GET_FEATURED_ITEMS = gql`
  query getFeaturedItems {
    getFeaturedItems {
      hero {
        heading
        description
        buttonText
        buttonUrl
      }
      stories {
        id
        content
        image
        video
        createdAt
        updatedAt
        likes {
          id
          user {
            id
            firstName
            lastName
            profile {
              pronouns
              picture
            }
          }
        }
        comments {
          id
          comment
          createdAt
          user {
            id
            firstName
            lastName
            profile {
              pronouns
              picture
            }
          }
        }
        user {
          id
          firstName
          lastName
          email
          role
          status
          createdAt
          updatedAt
          profile {
            pronouns
            picture
          }
        }
      }
      groups {
        id
        name
        description
        banner
        createdAt
        updatedAt
        featured
        membersCount
      }
      events {
        id
        title
        description
        banner
        createdAt
        updatedAt
        featured
        membersCount
        start
        end
      }
    }
  }
`;
